import React, { Component } from 'react'
import Login from './Login'
import AdminTabs from './AdminTabs'
import { infoUser } from '../../api/user'
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import errorlogger from '../../utils/errorlogger';
export default withCookies(class Admin extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props)
        const { cookies } = props;
        this.state = {
            isLoading: false,
            isLoggedIn: false,
            login: async () => {
                if (this.state.token !== null && this.state.token !== 'null') {

                    await infoUser({ token: this.state.token }).then(info => {
                        if (info._id !== null) {
                            this.setState({ isLoggedIn: true })
                        }
                    }).catch(error => {
                        console.log(errorlogger(error))
                    })

                }
            },
            logout: () => {
                this.setState({ 'token': null })
                sessionStorage.setItem("token", null)
                this.state.cookies.set("token", null, { path: "/" });

                this.setState({ 'isLoggedIn': null })
            },
            setToken: (token, isTemp = true) => {
                if (token !== null && token !== 'null') {
                    infoUser({ 'token': token })
                        .then((result) => {
                            if (typeof result._id !== 'undefined') {

                                this.setState({ 'user': result })
                                this.setState({ 'token': token })

                                if (!isTemp)
                                    this.state.cookies.set("token", token, { path: "/" });

                                sessionStorage.setItem("token", token)
                                this.state.login()
                            }
                            else {
                                this.setState({ error: result })
                                this.logout()
                            }

                        })
                        .catch((error) => console.log(errorlogger(error)))
                }
                else {
                    this.logout()
                }
            },
            cookies: cookies,
            token: cookies.get('token') || sessionStorage.getItem("token"),
        }


    }

    componentDidMount = async () => {
        this.state.login()
    }

    render() {
        return (
            this.state.isLoggedIn ?
                <AdminTabs logout={this.state.logout.bind(this)} />
                :
                <Login login={this.state.login.bind(this)} setToken={this.state.setToken.bind(this)} />
        );
    }
})

