import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import apis from '../../api/api'
class Credit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            artists: [],
            members: [],
            partners: [],
            isLoading: true
        }
    }
    componentDidMount = async () => {
        this.setState({ isLoading: true })
        await apis.getAllArtists().then(async artistes => {
            artistes = artistes.data.data
            artistes = artistes.reduce(function (map, obj) {
                map[obj._id] = obj;
                return map;
            }, {});
            await apis.getAllFiches().then(fiches => {
                fiches = fiches.data.data
                let artists = []
                fiches.forEach(element => {

                    const new_artists = element.artist.map(artiste => artistes[artiste])
                    let added_artist = []
                    new_artists.forEach(artist => {
                        const exist = artists.find(element => {
                            return element.find(el => el._id === artist._id)

                        })
                        if (typeof exist === 'undefined') {
                            added_artist.push(artist)
                        }
                    })
                    if (added_artist.length > 0)
                        artists.push(added_artist)
                })
                console.log(artists)


                artists = this.sortArtistByFistname(artists)
                this.setState({ artists: artists }, async () => {

                    await apis.getAllMembers().then(members => {
                        const membres = this.sortMemberByFistname(members.data.data)

                        this.setState({ members: membres }, async () => {
                            await apis.getAllPartners().then(partners => {
                                const partenaires = this.sortMemberByFistname(partners.data.data)

                                this.setState({ partners: partenaires }, () => this.setState({ isLoading: false }))
                            })
                        })
                    })

                })
            });
        })
    }
    sortArtistByFistname = (array) => {
        const newarray = array.sort((a, b) => {
            let nameA = '', nameB = ''
            if (a[0].lastname) {
                nameA = a[0].lastname.toLowerCase()
            }
            else {
                nameA = a[0].firstname.toLowerCase()
            }
            if (b[0].lastname) {
                nameB = b[0].lastname.toLowerCase()
            }
            else {
                nameB = b[0].firstname.toLowerCase()
            }
            console.log(nameA)
            console.log(nameB)
            console.log(nameA.localeCompare(nameB))
            return nameA.localeCompare(nameB);
        });
        console.log(newarray)
        return newarray;
    }
    sortMemberByFistname = (array) => {
        const newarray = array.sort(function (a, b) {
            let nameA = '', nameB = ''
            if (a.lastname) {
                nameA = a.lastname.toLowerCase()
            }
            else {
                nameA = a.firstname.toLowerCase()
            }
            if (b.lastname) {
                nameB = b.lastname.toLowerCase()
            }
            else {
                nameB = b.firstname.toLowerCase()
            }
            return nameA.localeCompare(nameB);
        });
        return newarray;
    }
    render() {
        const { t } = this.props;
        const { artists, members, partners, isLoading } = this.state
        const text = t('credit.collective-text', { returnObjects: true }).toString().replace(/,/g, '')

        return (
            isLoading ?
                'loading...'
                :
                <div className="credits">
                    {/* <h2>{t('info.credit')}</h2> */}
                    <div className='credit-artists'>
                        <h3>{t('credit.artists')}</h3>
                        <ul>
                            {
                                artists ?
                                    artists.map((artists_list, i) =>
                                        <li key={'artists_' + i}>

                                            {artists_list.map((artist, j) =>
                                                <span key={`artist_${j}`}>
                                                    {artist.url ?
                                                        <a href={artist.url} target="_blank" rel="noopener noreferrer" >
                                                            {artist.firstname} {artist.lastname}

                                                        </a>
                                                        :
                                                        <span className='like-link'> {artist.firstname} {artist.lastname}</span>
                                                    }

                                                    {artists_list.length > 1 && j < artists_list.length - 1 && ' & '}
                                                </span>
                                            )}
                                        </li>
                                    )
                                    :
                                    'Aucun artistes...'
                            }
                        </ul>
                    </div>
                    <div className='credit-members'>
                        <h3>{t('credit.nt2')}</h3>
                        <ul>
                            {
                                members ?
                                    members.map((member, i) =>
                                        <li key={'member_' + i}><a href={member.url} target="_blank" rel="noopener noreferrer" >{member.firstname} {member.lastname}</a> {member.role && <span>({member.role})</span>}</li>
                                    )
                                    :
                                    'Aucun Membre de Archiver le Présent Curating Collective'
                            }
                        </ul>
                        <div
                            className="info-example"
                            dangerouslySetInnerHTML={{
                                __html: text
                            }}
                        ></div>
                    </div>
                    <div className='credit-partenaires'>
                        <h3>{t('credit.partenaires')}</h3>
                        <ul>
                            {
                                partners ?
                                    partners.map((partenaire, i) =>
                                        <li key={'partenaire_' + i}><a href={partenaire.url} target="_blank" rel="noopener noreferrer" >{this.props.lang === 'en' ? partenaire.lastname : partenaire.firstname}</a> {partenaire.role && <span>({partenaire.role})</span>}</li>
                                    )
                                    :
                                    'Aucun Partenaire trouvé'
                            }
                        </ul>

                    </div>
                </div>
        );

    }
}

export default withTranslation()(Credit)