import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Credit from './Credit'
import About from './About'
class Info extends Component {
    render() {
        const { t, lang } = this.props;

        return (
            <div id="modal-info" className="modal-content">
                <h1>{t('site.title')}</h1>
                <About lang={lang} />
                <Credit lang={lang} />
            </div>
        );

    }
}

export default withTranslation()(Info)