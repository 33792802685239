import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faVideo, faVolumeUp, faImage, faFile } from '@fortawesome/free-solid-svg-icons'
import FicheMediaPopup from './FicheMediaPopup'
import i18n from '../utils/i18n';

export default class FicheMedia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }
    openFile = () => {
        this.setState({ open: !this.state.open })

    }
    isImage = (path) => {
        return (
            path.includes('.jpg') ||
            path.includes('.png') ||
            path.includes('.gif')
        )
    }
    isVideo = (path) => {
        return (
            path.includes('.mp4') ||
            path.includes('.mov') ||
            path.includes('.avi')
        )
    }
    isAudio = (path) => {
        return (
            path.includes('.mp3') ||
            path.includes('.wav') ||
            path.includes('.wma')
        )
    }
    isText = (path) => {
        return (
            path.includes('.docx') ||
            path.includes('.txt') ||
            path.includes('.pdf')
        )
    }
    render() {
        const { media } = this.props
        const { open } = this.state
        const path = media.file.path

        let logo = null;
        if (media.file) {
            if (this.isAudio(path)) {
                logo = faVolumeUp
            } else if (this.isImage(path)) {
                logo = faImage
            } else if (this.isText(path)) {
                logo = faFile
            } else if (this.isVideo(path)) {
                logo = faVideo
            } else {
                logo = faStickyNote
            }
        }
        else {
            logo = faStickyNote;
        }

        return (
            <div className={open ? "media-folder open" : "media-folder"} >
                <div className="media-preview" onClick={this.openFile}>

                    <FontAwesomeIcon icon={logo} />

                    <h3>{i18n.language === 'en' ? media.title_en : media.title_fr}</h3>
                </div>
                {this.state.open &&
                    <FicheMediaPopup media={media} close={this.openFile} />
                }

            </div>
        );
    }
}