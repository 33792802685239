import React, { Component } from 'react'

// import video from '../../../files/framed_scrolling.mp4'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";

export default class FramedScrolling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startTime: 0,
            fullscreen: false,
            isLoading: true,
            opacity: 0
        }
    }
    componentDidMount = () => {
        const nbseconds = (60 * 12) + 53
        this.setState({ startTime: Math.floor(Math.random() * nbseconds) })
    }
    goFullscreen = () => {
        var elem = document.getElementById('win-modal');
        elem.setAttribute('class', 'fullscreen')
        this.setState({ fullscreen: true })
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
        let xbtn = document.getElementById('ferme-modal')
        xbtn.setAttribute('class', 'hidden')
    }
    exitFullscreen = () => {
        var elem = document.getElementById('win-modal');
        elem.setAttribute('class', '')
        this.setState({ fullscreen: false })
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }

        let xbtn = document.getElementById('ferme-modal')
        xbtn.setAttribute('class', '')

    }
    render() {
        const { opacity } = this.state;
        const public_api = "https://medias.production.nt2.ca/research/files/"
        const file_name = "framed_scrolling.mp4"
        return (
            <div className='framed-scrolling-content'>
                {
                    opacity === 0 &&
                    <div>Loading...</div>
                }
                <video style={{ opacity: opacity }} id='video-oeuvre' width="320" height="240" loop={true} autoPlay={true} preload='auto' onLoadedData={() => this.setState({ opacity: 1 })}>
                    <source src={`${public_api}${file_name}#t=${this.state.startTime}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {
                    this.state.fullscreen ?
                        <button style={{ opacity: opacity }} id='fullscreen-btn' onClick={this.exitFullscreen}>
                            <FontAwesomeIcon icon={faCompress} />
                        </button>
                        :
                        <button style={{ opacity: opacity }} id='fullscreen-btn' onClick={this.goFullscreen}>
                            <FontAwesomeIcon icon={faExpand} />
                        </button>
                }
            </div>

        );

    }
}