import React, { Component } from 'react'
import apis from '../../../api/api'
import errorlogger from '../../../utils/errorlogger'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from 'axios'

class MediaForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            data: { name: "", path: "" },
            progresss: 0,
            title_fr: "",
            title_en: "",
            description_fr: "",
            description_en: "",
            _id: -1,

            editor_fr: null,
            editor_en: null
        }

    }
    componentDidMount = () => {
        this.prepareMedia()
        console.log(this.props)
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.selected_media !== this.props.selected_media ||
            prevProps.selected_media_id !== this.props.selected_media_id
        ) {
            this.prepareMedia()
        }
    }
    prepareMedia = () => {
        if (this.props.selected_media !== null) {
            const { _id, title_fr, title_en, description_en, description_fr } = this.props.selected_media
            this.setState({ _id, title_fr, title_en, description_en, description_fr }, () => console.log(this.state))
        }
        else {
            this.setState({ _id: -1, title_fr: "", title_en: "", description_en: "", description_fr: "" })
        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleDelete = (event) => {
        event.preventDefault()

        apis.deleteMedia(this.state._id).then((res) => {
            alert('Media deleted')
        })
    }
    setProgess = (progress) => {
        this.setState({ progress })
    }
    setFile = (file) => {
        this.setState({ file })
    }
    getFile = (data) => {
        this.setState({ data })
    }
    onDrop = (e) => {
        this.setProgess(0)
        const file = e.target.files[0]; // accesing file
        console.log(file);
        this.setFile(file); // storing file
    }
    onEditorChange = (editor) => {
        this.setState({
            description_fr: this.state.editor_fr.getData(),
            description_en: this.state.editor_en.getData(),
        });
    }

    uploadFile = () => {
        const formData = new FormData(); formData.append('file', this.state.file); // appending file
        axios.post(process.env.REACT_APP_API_URL + '/upload', formData, {
            onUploadProgress: (ProgressEvent) => {
                let progress = Math.round(
                    ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                this.setProgess(progress);
            }
        }).then(res => {
            console.log(res);
            this.getFile({
                name: res.data.name,
                path: process.env.REACT_APP_API_URL + res.data.path
            })
        }).catch(err => console.log(err))
    }
    onFormSubmit = (e) => {

        e.preventDefault();

        const { title_en, title_fr, description_en, description_fr, data, _id } = this.state

        if (this.props.selected_media && this.props.selected_media_id !== -1) {
            const payload = { title_en, title_fr, description_en, description_fr, file: data, _id }
            apis.updateMedia(payload)
                .then((response) => {
                    alert("The file is successfully uploaded");
                })
                .catch((error) => {

                });
        }
        else {
            const payload = { title_en, title_fr, description_en, description_fr, file: data }
            apis.insertMedia(payload)
                .then((response) => {
                    alert("The file is successfully updated");
                })
                .catch((error) => {
                    console.log(errorlogger(error))
                });
        }
    }

    render() {
        const { _id, title_fr, title_en, description_en, description_fr } = this.state
        return (
            <div>
                <form onSubmit={this.onFormSubmit} id="form-media" method="post">
                    <p key="title_fr_media">
                        <span>Titre Fr : </span>
                        <input type="text" name="title_fr" className="media_input" id="title_fr_input_media" value={title_fr} placeholder="Titre Fr" onChange={this.handleChange} />
                    </p>
                    <p key="title_en_media">
                        <span>Titre En : </span>
                        <input type="text" name="title_en" className="media_input" id="title_en_input_media" value={title_en} placeholder="Titre En" onChange={this.handleChange} />
                    </p>

                    <p key="description_fr_media">
                        <span>Description Fr : </span>
                        {description_fr !== null || _id === -1 ?
                            <CKEditor
                                editor={ClassicEditor}
                                data={description_fr}
                                onChange={this.onEditorChange}
                                onInit={(editor) => {
                                    this.setState({ editor_fr: editor })

                                }}
                                config={
                                    {
                                        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                        heading: {
                                            options: [
                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                                { model: 'bordered', view: 'div', title: 'Bordered', class: 'bordered' },
                                                { model: 'centered', view: 'div', title: 'Centered', class: 'centered' }
                                            ]
                                        }
                                    }
                                }
                            // config={
                            //     {
                            //         toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                            //         heading: {
                            //             options: [
                            //                 { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            //                 { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            //                 { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            //                 { view: 'div', title: 'Zone de Fichier de texte', class: 'text-file' },
                            //             ]
                            //         }
                            //     }
                            // }
                            />
                            :
                            'Loading...'
                        }
                    </p>
                    <p key="description_en_media">
                        <span>Description En : </span>
                        {description_en !== null || _id === -1 ?
                            <CKEditor
                                editor={ClassicEditor}
                                data={description_en}
                                onChange={this.onEditorChange}
                                onInit={(editor) => this.setState({ editor_en: editor })}
                            />
                            :
                            'Loading...'
                        }
                    </p>
                    <p key="files_media">
                        <span>Fichiers : </span>
                        <div className="file-upload">
                            <input type="file" onChange={this.onDrop} />
                            <div className="progessBar" style={{ width: this.state.progress }}>
                                {this.state.progress}
                            </div>
                            <button type="button" onClick={this.uploadFile}>Upload</button>
                        </div>
                    </p>

                    {
                        this.props.selected_media &&
                        <button type="button" onClick={this.handleDelete}>Supprimer</button>

                    }
                    <button type="submit">{_id === -1 ? 'Ajouter' : 'Modifier'}</button>
                </form>
            </div >
        );

    }

}




export default MediaForm