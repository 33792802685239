import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import apis from '../../api/api'
export default class TextsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            texts: null,
            cur_item: null,
            cur_id: null,
            editor_fr: null,
            editor_en: null,
            text_fr: '',
            text_en: ''
        }
    }
    onEditorChange = (editor) => {
        this.setState({
            cur_item: {
                text_fr: this.state.editor_fr.getData(),
                text_en: this.state.editor_en.getData(),
            }
        });
    }
    componentDidMount = async () => {
        await apis.getAllTexts().then(async texts => {
            texts = texts.data.data
            this.setState({
                texts: texts
            })
        })
    }
    updateAbout = async (e) => {
        e.preventDefault()
        const { cur_id, cur_item } = this.state
        try {
            await apis.updateText({ id: cur_id, text_en: cur_item.text_en, text_fr: cur_item.text_fr }).then(infos => {
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    setCurText = async (e) => {
        e.persist()
        let id = e.target.value
        this.setState({ cur_id: id })
        await apis.getTextById(id).then(res => {
            res = res.data.data
            this.setState({ cur_item: res })
        })
    }
    render() {
        const { texts, cur_item } = this.state
        return (

            texts ?
                <div>
                    < TextsSelector texts={texts} queryText={this.setCurText} />
                    {cur_item &&
                        <div>
                            <CKEditor
                                editor={ClassicEditor}
                                data={cur_item.text_fr}
                                onChange={this.onEditorChange}
                                onInit={(editor) => this.setState({ editor_fr: editor })}
                            />
                            <CKEditor
                                editor={ClassicEditor}
                                data={cur_item.text_en}
                                onChange={this.onEditorChange}
                                onInit={(editor) => this.setState({ editor_en: editor })}
                            />
                            <button onClick={this.updateAbout}>Modifier</button>
                        </div>
                    }
                </div >
                :
                <div>
                    Loading...
                </div>

        )
    }
}

export class TextsSelector extends Component {

    render = () => {
        return (
            <select id={'select_texts'} onChange={this.props.queryText}>
                {this.props.texts.map(text => {
                    return <option key={text._id} value={text._id} >{text.name}</option>
                })}
            </select>
        )
    }
}