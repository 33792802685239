import React, { Component } from 'react'

import $ from 'jquery';

import { withTranslation } from 'react-i18next';
import apis from '../api/api'
class SearchError extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            content: ''
        }
    }

    hideError = () => {
        if (this.state.open) {
            $('.search-error').slideUp('slow');
        }
    }
    showError = () => {
        if (!this.state.open) {
            $('.search-error').slideToggle('slow');
        }
    }

    componentDidMount = () => {
        $('.search-error').hide()
        if (this.props.show) {
            this.showError()
        }
        $("body").click((e) => {
            if ($(".search-error").is(":visible")) {
                if (!$(".search-error").find(e.target).length > 0) {
                    if (!$("#formulaire").find(e.target).length > 0) {
                        this.hideError()
                        this.setState({ open: false })
                    }
                }
            }
        });
        let text = ''
        this.props.empty ? text = 'intro' : text = 'error';
        apis.getText(text).then((result) => {
            result = result.data.data
            if (this.props.lang === 'en') {
                this.setState({ content: result.text_en })
            }
            else {
                this.setState({ content: result.text_fr })
            }
        })
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {

            const { show } = this.props
            if (show) {
                if (!this.state.open) {
                    this.showError()
                    this.setState({ open: true })
                }
            }
            else {
                if (this.state.open) {
                    this.hideError()
                    this.setState({ open: false })
                }
            }
        }
        if (prevProps.empty !== this.props.empty ||
            prevProps.lang !== this.props.lang) {
            let text = ''
            this.props.empty ? text = 'intro' : text = 'error';
            apis.getText(text).then((result) => {
                result = result.data.data
                if (this.props.lang === 'en') {
                    this.setState({ content: result.text_en })
                }
                else {
                    this.setState({ content: result.text_fr })
                }
            })
        }

    }

    render() {
        const text = this.state.content
        return (

            <div className='search-error' onClick={this.hideError} dangerouslySetInnerHTML={{ __html: text }} />

        );
    }
}

export default withTranslation()(SearchError);