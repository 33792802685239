import axios from 'axios'
import i18n from '../utils/i18n';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api',
})


export const updateText = payload => api.patch(`/texts`, payload)
export const getText = name => api.get(`/text/${name}`)
export const getTextById = id => api.get(`/textbyid/${id}`)
export const getAllTexts = payload => api.get(`/texts`, payload)

export const insertFiche = payload => api.post(`/fiche`, payload)
export const getAllFiches = () => api.get(`/fiches`)
export const updateFicheById = (id, payload) => api.put(`/fiche/${id}`, payload)
export const deleteFicheById = id => api.delete(`/fiche/${id}`)
export const getFicheById = id => api.get(`/fiche/${id}`)
// export const getFichesByKey = idkeyword => api.get(`/fiches/${idkeyword}`)
export const getFichesByKey = idkeyword => api.get(`/fiches/keyword/${idkeyword}`)

export const getAllCredits = () => api.get(`/credits`)
export const getAllArtists = () => api.get(`/credits/artists`)
export const getAllMembers = () => api.get(`/credits/members`)
export const getAllPartners = () => api.get(`/credits/partners`)
export const insertCredit = payload => api.post(`/credit`, payload)
export const updateCreditById = (id, payload) => api.put(`/credit/${id}`, payload)
export const deleteCreditById = id => api.delete(`/credit/${id}`)
export const getCreditById = id => api.get(`/credit/${id}`)
export const getCreditByArrayId = payload => api.post(`/credits`, payload)

export const insertKeyword = payload => api.post(`/keyword`, payload)
export const getAllKeywords = () => api.get(`/keywords`)
export const getAllKeywordsWithKeys = () => api.get(`/keywords/true`)
export const updateKeywordById = (id, payload) => api.patch(`/keyword/${id}`, payload)
export const deleteKeywordById = id => api.delete(`/keyword/${id}`)
export const getKeywordById = id => api.get(`/keyword/${id}`)
export const getKeywordByTitle = title => api.get(`/keyword/title/${title}/field/title_${i18n.language}`)
// export const getKeywordByTitle = title => api.get(`/keyword/title/${title}/field/title_${i18n.language}`, {
//     validateStatus: function (status) {
//         return status >= 200 && status < 500;
//     }
// })

export const getAllMedias = () => api.get(`/medias`)
export const getMedia = (id) => api.get(`/media/${id}`)
export const insertMedia = (payload) => api.post(`/media`, payload)
export const updateMedia = (payload) => api.patch(`/media`, payload)
export const deleteMedia = id => api.delete(`/media/${id}`)



const apis = {
    insertFiche,
    getAllFiches,
    updateFicheById,
    deleteFicheById,
    getFicheById,
    getFichesByKey,

    insertKeyword,
    getAllKeywords,
    getAllKeywordsWithKeys,
    updateKeywordById,
    deleteKeywordById,
    getKeywordById,
    getKeywordByTitle,

    getAllCredits,
    getAllArtists,
    getAllMembers,
    getAllPartners,
    insertCredit,
    updateCreditById,
    deleteCreditById,
    getCreditById,
    getCreditByArrayId,

    updateText,
    getText,
    getTextById,
    getAllTexts,
    
    getAllMedias,
    getMedia,
    insertMedia,
    updateMedia,
    deleteMedia,
}


export default apis