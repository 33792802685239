import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import './style'
import { CookiesProvider } from 'react-cookie';

import './utils/i18n';

import ReactGA from 'react-ga';

const trackingId = "UA-42669822-47"; // Replace with your Google Analytics tracking ID
const initializeReactGA = () => {
  ReactGA.initialize(trackingId);
  ReactGA.pageview('/homepage');
}

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
  ,
  document.getElementById("root")
);

initializeReactGA()
serviceWorker.unregister();