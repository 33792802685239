import React, { Component } from 'react'

import Vimeo from '@u-wave/react-vimeo';
import i18n from '../utils/i18n';
import { withTranslation } from 'react-i18next';

class VideoContent extends Component {


    render() {
        const { infos, setInfosFiche, t } = this.props
        const { url, title_fr, title_en } = infos
        const title = i18n.language === 'en' ? title_en : title_fr

        return (
            <div className='vimeo'>
                <button className='back-btn' onClick={() => setInfosFiche(infos)}>{t('fiche.back')}</button>
                <h1>{title}</h1>
                <Vimeo
                    video={url}
                    autoplay
                />
            </div>
            // <Vimeo videoId={this.props.videoId} autoplay={true} />
        );

    }
}

export default withTranslation()(VideoContent)