import React, { Component } from 'react'
import Terminal from 'terminal-in-react';
import './terminal.css'
import apis from '../api/api'
// import { array } from 'prop-types';

export default class TerminalWin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fiches: null,

            keywords: null,

            artists: null,
            members: null,
            partners: null,

            selected_fiche: null,
        }
    }
    componentDidMount = async () => {
        await apis.getAllFiches().then(fiches => {
            this.setState({ fiches: fiches.data.data })
        })
        await apis.getAllKeywordsWithKeys().then(keywords => {
            this.setState({ keywords: keywords.data.data })
        })
        await apis.getAllArtists().then(artists => {
            this.setState({ artists: artists.data.data })
        })
        await apis.getAllMembers().then(members => {
            this.setState({ members: members.data.data })
        })
        await apis.getAllPartners().then(partners => {
            this.setState({ partners: partners.data.data })
        })
    }
    listContent = (args, print, runCommand) => {
        const param = args.slice(1).join(' ');
        if (param === "--keywords") {
            const keywords = Object.keys(this.state.keywords).map((key) => { let keyword = this.state.keywords[key]; return keyword.title_fr + "\n" })
            return keywords
        }
        else if (param === "--artists") {
            const artists = this.state.artists.map((artist) => artist.firstname + " " + artist.lastname + "\n")
            return artists
        }
        else if (param === "--partners") {
            const partners = this.state.partners.map((partner) => partner.firstname + "\n")
            return partners
        }
        else if (param === "--members") {
            const members = this.state.members.map((member) => member.firstname + " " + member.lastname + "\n")
            return members
        }
        else {
            if (this.state.selected_fiche) {
                let fiche = []
                for (var key in this.state.selected_fiche) {
                    if (this.state.selected_fiche.hasOwnProperty(this.state.selected_fiche)) {
                        if (key === 'keywords') {
                            const keywords = this.state.selected_fiche[key].map((keyword) => this.state.keywords[keyword].title_fr)
                            fiche.push(key + " : " + keywords + "\n")
                        }
                        else if (key === "artist") {
                            const artists = this.state.selected_fiche[key].map((artist) => {
                                let artist_content = this.state.artists.find((element) => element._id === artist)
                                return artist_content.firstname + " " + artist_content.lastname
                            })
                            fiche.push(key + " : " + artists + "\n")
                        }
                        else if (key !== '_id') {
                            fiche.push(key + " : " + this.state.selected_fiche[key] + "\n")
                        }
                    }
                }
            
                return fiche
            }
            else {
                const fiches = this.state.fiches.map((fiche) => fiche.title_fr + "\n")

                return fiches
            }
        }
    }
    selectFiche = (args, print, runCommand) => {
        const fiche = args.slice(1).join(' ');
        console.log(fiche)
        print('');

        if (fiche !== "" || fiche !== "..") {
            let selected = this.state.fiches.find((element) => element.title_fr === fiche)
            if (selected) {
                this.setState({ selected_fiche: selected })
                return "Fiche " + selected.title_fr + " sélectionnée"
            }
        }
        this.setState({ selected_fiche: null })
        return "Aucune fiche sélectionnée."

    }
    render() {

        return (
            <div id="modal-terminal" className="modal-content">
                <Terminal
                    color='green'
                    backgroundColor='black'
                    barColor='black'
                    style={{ fontWeight: "bold", fontSize: "1em" }}
                    commands={{
                        'ls': this.listContent,
                        'cd': this.selectFiche,
                        popup: () => alert('Terminal in React')
                    }}
                    descriptions={{
                        'ls': 'Lister toutes les fiches ou lister le contenu d\'une fiche\n' +
                            '\t--keywords\t Lister toutes les Mots-Clés\n' +
                            '\t--partners\t Lister toutes les Partenaires\n' +
                            '\t--artists\t Lister toutes les Artistes\n' +
                            '\t--members\t Lister toutes les Membres du Collectif\n',
                        'cd Nom de la fiche': 'Ouvrir une fiche',
                        alert: 'alert', popup: 'alert'
                    }}
                    msg='Bienvenue dans le Terminal Re|Search. Vous avez trouver le easter egg.'
                    watchConsoleLogging
                    commandPassThrough={cmd => `-ResearchBash:${cmd}: La commande n'existe pas`}

                />
            </div>

        );
    }
}