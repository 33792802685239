import React, { Component } from 'react'

import apis from '../api/api'

import { withTranslation } from 'react-i18next';

import Oeuvre from './Oeuvre'

class ListeOeuvres extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fiches: [],
            searched_fiches: [],
            isLoading: false,
            isSearching: false,
            limit_style: {
                size: 0,
                minwidth: 100,
                minpadding: 1.5,
                maxpadding: 3
            },
            all_keywords: null
        }
    }

    componentDidUpdate = async (prevProps) => {
        const { searched_keyword } = this.props
        if (prevProps.searched_keyword !== searched_keyword) {
            if (searched_keyword !== null) {
                let id = searched_keyword._id
                await apis.getFichesByKey(id)
                    .then((fiches) => {
                        this.setState({ searched_fiches: fiches.data.data })
                    })
                    .catch((e) => this.setState({ searched_fiches: [] }))
            }
            else {
                this.setState({ searched_fiches: [] })
            }
            this.prepareFiches(this.state.fiches)
        }
        if (prevProps.lang !== this.props.lang) {
            this.prepareFiches(this.state.fiches)
        }
    }
    sortByTitle = (array) => {
        const lang = this.props.lang
        const newarray = array.sort(function (a, b) {
            let nameA = '', nameB = ''
            if (lang === 'en') {
                nameA = a.title_en.toLowerCase()
                nameB = b.title_en.toLowerCase()
            }
            else {
                nameA = a.title_fr.toLowerCase()
                nameB = b.title_fr.toLowerCase()
            }
            return nameA.localeCompare(nameB);
        });
        return newarray;
    }
    componentDidMount = async () => {
        this.setState({ isLoading: true })

        await apis.getAllFiches().then(fiches => {

            this.prepareFiches(fiches.data.data)
            this.setState({ isLoading: false })
        })
        await apis.getAllKeywordsWithKeys().then(items => {
            items = items.data.data
            this.setState({
                all_keywords: items,
            })
        })
    }
    itemInArray = (item) => {
        let existe = false
        this.state.searched_fiches.forEach(element => {
            if (element._id === item._id) {
                existe = true
            }
        });
        return existe
    }
    prepareFiches(fiches) {
        fiches = this.sortByTitle(fiches);
        //Check if Searching or not
        let isSearching = this.props.searched_keyword !== null
        this.setState({ isSearching: isSearching })
        //Check if Oeuvre is Searched

        let size = fiches.length
        let limit_style =
        {
            size: size,
            minwidth: 100 - ((size - 1) * 3),
            minpadding: 0,
            maxpadding: size * 0.1
        }
        for (let index = 0; index < size; index++) {
            let element = fiches[index]
            element.index = index
            element.isSearched = false
            if (isSearching && this.itemInArray(element)) {
                element.isSearched = true
            }
            element.isSearching = isSearching

            fiches[index] = element;
        }
        this.setState({ fiches: fiches, limit_style: limit_style })


    }

    render() {
        const { fiches, isSearching, all_keywords, limit_style } = this.state
        const { t, isGrille } = this.props;
        const lang = this.props.lang

        if (typeof fiches !== 'undefined' && fiches.length > 0) {
            return (
                <section className={isGrille ? "oeuvres oeuvres-grille" : isSearching ? 'oeuvres oeuvres-search searching' : 'oeuvres oeuvres-search'}>
                    {
                        fiches.map(oeuvre => {
                            return <Oeuvre key={oeuvre._id} lang={lang} all_keywords={all_keywords} infos={oeuvre} index={oeuvre.index} isGrille={isGrille} isSearched={oeuvre.isSearched} isSearching={isSearching} limits={limit_style} setInfosFiche={this.props.setInfosFiche} />
                        })
                    }
                </section>
            )
        }
        else if (this.state.isLoading) {
            return (
                <section className='loading'>
                    {t('site.loading')}
                </section>
            )
        }
        else {
            return null
        }
    }
}

export default withTranslation()(ListeOeuvres)