import React, { Component } from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';

import { SearchBar, BottomBar, ListeMediatheque, FramedScrolling, ListeOeuvres, Info, Fiche, Admin, Modal, VideoContent, TerminalWin } from '../Components'
import { withCookies } from 'react-cookie';

import i18n from '../utils/i18n';

import apis from '../api/api'

import rotate from '../img/rotate.png'

import $ from 'jquery'
// import { Modal } from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searched_keyword: null,
      displayIsGrille: false,
      isDarkMode: true,
      barIsTop: false,
      infos_fiche: null,
      all_keywords: null,
      lang: i18n.language,
      modalContent: '',
      modalContentName: '',
      active_page_expo: true,
    }
  }
  setSearchedKeyword = (value) => {
    this.setState({ searched_keyword: value })
    this.setModalContent(null)
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({ lang: i18n.language })

  }
  setDisplayGrille = () => {
    this.setState({ displayIsGrille: !this.state.displayIsGrille })
    this.setModalContent('')
    this.setSearchedKeyword(null)
  }
  setDisplayMode = () => {
    if (!this.state.isDarkMode) {
      $("#favicon").attr("href", "favicon.png");
    }
    else {
      $("#favicon").attr("href", "favicon2.png");
    }
    this.setState({ isDarkMode: !this.state.isDarkMode })
  }
  closeModal = () => {
    // this.setState({ modalContent: '', modalContentName: '' })
  }
  changePage = () => {
    // const expo = document.getElementById('exposition')
    // const mediatheque = document.getElementById('mediatheque')
    // if (this.state.active_page_expo) {
    //   expo.style.left = "100vw";
    //   mediatheque.style.left = "0";
    // }
    // else {
    //   expo.style.left = "0";
    //   mediatheque.style.left = "-100vw";

    // }
    this.setState({ active_page_expo: !this.state.active_page_expo })

  }
  setInfosFiche = (infos) => {
    let fiche = <Fiche closeFiche={() => this.setModalContent('')} setModalContent={this.setModalContent} all_keywords={this.state.all_keywords} infos_fiche={infos} setSearchedValue={this.setSearchedKeyword} />
    this.setState({ modalContent: fiche, modalContentName: 'fiche' })
  }

  setModalContent = (name, infos = null) => {
    let content = '';

    switch (name) {
      case 'info':
        content = <Info lang={this.state.lang} />
        break;
      case 'admin':
        content = <Admin cookies={this.props.cookies} />
        break;
      case 'framed':
        content = <FramedScrolling />
        break;
      case 'vimeo':
        content = <VideoContent infos={infos} setInfosFiche={this.setInfosFiche} />
        break;
      case 'terminal':
        content = <TerminalWin />
        break;
      default:
        content = ''
        break;
    }

    this.setState({ modalContent: content, modalContentName: name })

  }
  checkParams = () => {
    let url_string = window.location.href;
    var url = new URL(url_string);
    return url.searchParams.get("oeuvre");
  }
  componentDidMount = async () => {
    await apis.getAllKeywordsWithKeys().then(items => {
      items = items.data.data
      this.setState({
        all_keywords: items,
      })
    })
    if (this.checkParams() !== null) {
      let oeuvre_id = this.checkParams()
      await apis.getFicheById(oeuvre_id).then(infos => {
        this.setInfosFiche(infos.data.data)
      })
    }
  }
  render = () => {
    const { displayIsGrille, searched_keyword, lang, modalContent, isDarkMode, active_page_expo } = this.state
    const displayClass = isDarkMode ? 'dark' : 'light'
    const searchIsTop = displayIsGrille || searched_keyword !== null
    return (
      <div className={"App " + displayClass}>
        <div id={"exposition"} className={active_page_expo ? "active" : ""}>
          <SearchBar searched_keyword={searched_keyword} isTop={searchIsTop} setSearchedKeyword={this.setSearchedKeyword} setModalContent={this.setModalContent} lang={lang} isGrille={this.state.displayIsGrille} />
          <ListeOeuvres isGrille={displayIsGrille} searched_keyword={searched_keyword} setInfosFiche={this.setInfosFiche} lang={lang} />
        </div>
        <div id="mediatheque" className={!active_page_expo ? "active" : ""}>
          <ListeMediatheque />
        </div>
        <BottomBar setDisplayMode={this.setDisplayMode} changePage={this.changePage} page_is_expo={this.state.active_page_expo} isDark={this.state.isDarkMode} setDisplayGrille={this.setDisplayGrille} isGrille={this.state.displayIsGrille} setModalContent={this.setModalContent} changeLanguage={this.changeLanguage} lang={lang} />
        <Modal closeModal={this.closeModal} name={this.state.modalContentName}>
          {modalContent}
        </Modal>
        <img src={rotate} className='rotate' />
      </div>
    );
  }
}
export default withCookies(App);