import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import apis from '../../api/api'
class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: ''
        }
    }
    componentDidMount = () => {
        apis.getText('about').then((result) => {
            result = result.data.data
            if (this.props.lang === 'en') {
                this.setState({ content: result.text_en })
            }
            else {
                this.setState({ content: result.text_fr })
            }
        })
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.lang !== this.props.lang) {
            apis.getText('about').then((result) => {
                result = result.data.data
                if (this.props.lang === 'en') {
                    this.setState({ content: result.text_en })
                }
                else {
                    this.setState({ content: result.text_fr })
                }
            })
        }
    }
    render() {
        let content = this.state.content

        return (
            <div id="modal-info" className="modal-content">
                <div
                    className="info-content"
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                />
            </div>
        );

    }
}

export default withTranslation()(About)