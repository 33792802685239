import React, { Component } from 'react'
import $ from "jquery";

export default class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cur_content: '',
            cur_name: ''
        }
    }
    componentDidMount() {
        $('#body-modal').hide();
        $("#body-modal").click((e) => {
            if ($("#win-modal").is(":visible")) {
                if (!$("#win-modal").find(e.target).length > 0) {
                    this.closeModal()
                }
            }
        });
    }
    openModal = (event) => {
        this.setState({ cur_content: this.props.children, cur_name: this.props.name },
            () => $('#body-modal').slideToggle('slow')
        );
    }
    closeModal = async (event, open = false) => {
        await $('#body-modal').slideUp('slow',
            () => this.setState({ cur_content: '', cur_name: '' },
                () => {
                    if (open)
                        this.openModal()
                }
            )
        );
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.children !== this.props.children) {
            console.log(this.props.children)
            if (this.props.children) {
                if (this.props.children) {
                    if (this.state.cur_name !== this.props.name) {
                        this.closeModal(null, true)
                    }
                    else {
                        this.closeModal()
                    }
                }
            }
            else {
                this.closeModal()
            }
        }
    }
    render() {
        return (
            <div id='body-modal'>
                <section id="win-modal">
                    <span id="ferme-modal" onClick={(event) => this.closeModal(event)}>x</span>
                    {
                        this.state.isLoading ?
                            'Loading...'
                            :
                            this.state.cur_content

                    }
                </section>
            </div>

        );
    }
}