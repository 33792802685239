import React, { Component } from 'react'
import './admin.css'
import Tab from './Tab'
import TextsEdit from './TextsEdit';
import Mediatheque from './Mediatheque/Mediatheque';
export default class AdminTabs extends Component {
    openTab(evt, name) {
        // Declare all variables
        var i, tabcontent, tablinks;

        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(name).style.display = "block";
        evt.currentTarget.className += " active";
    }

    render() {

        return (
            <nav id='login-bar'>


                <div className="tabs">

                    <div className="tab main">
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'fiches')}>Fiches</button>
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'mots-cles')}>Mots-Clés</button>
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'users')}>Utilisateurs</button>
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'credits')}>Credits</button>
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'about')}>Textes</button>
                        <button className="tablinks" onClick={(event) => this.openTab(event, 'media')}>Mediatheque</button>
                        <button className="tablinks" onClick={(event) => this.props.logout()}>Logout</button>
                    </div>

                    <div id='fiches' className='tabcontent'>
                        <h2>Fiches</h2>
                        <Tab type='fiches' />
                    </div>

                    <div id="mots-cles" className="tabcontent">
                        <h2>Mots-Clés</h2>
                        <Tab type='keywords' />
                    </div>

                    <div id="users" className="tabcontent">
                        <h2>Utilisateurs</h2>
                        <Tab type='users' />
                    </div>
                    <div id="credits" className="tabcontent">
                        <h2>Crédits</h2>
                        <Tab type='credits' />
                    </div>
                    <div id="about" className="tabcontent">
                        <h2>Textes</h2>
                        <TextsEdit />
                    </div>
                    <div id="media" className="tabcontent">
                        <h2>Mediatheque</h2>
                        <Mediatheque />
                    </div>
                </div>
            </nav>

        );
    }
}
