import React, { Component } from 'react'
import apis from '../../api/api'

export class CreditsInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            firstname: "",
            lastname: "",
            url: "",
            role: "",
            isArtist: false,
            isPartner: false,
            success: "",
            error: "",

        }
    }
    componentDidUpdate = (prevProps, prevStates) => {
        if (prevProps.credit !== this.props.credit) {
            let { _id, firstname, lastname, url, role, isArtist, isPartner } = this.props.credit

            this.setState({
                id: _id || "",
                firstname: firstname || "",
                lastname: lastname || "",
                url: url || "",
                role: role || "",
                isArtist: isArtist || false,
                isPartner: isPartner || false,
            })
            
        }

    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (this.state.success !== "" || this.state.error !== "") {
            this.setState({ success: "", error: "" })
        }
    }
    deleteCredits = async (e) => {
        e.preventDefault()
        let id = this.state.id
        try {
            await apis.deleteCreditById(id).then(infos => {
                this.setState({
                    success: "Credits supprimée"
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({

                    error: error.response.data
                })
            }
        }
    }
    updateCredits = async (e) => {
        e.preventDefault()
        const { id, firstname, lastname, url, isArtist, isPartner, role } = this.state
        try {
            await apis.updateCreditById(id, { 'firstname': firstname, 'lastname': lastname, 'url': url, 'isArtist': isArtist, 'isPartner': isPartner, 'role': role }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }
    }
    insertCredits = async (e) => {
        e.preventDefault()
        const { firstname, lastname, url, isArtist, role } = this.state

        try {
            await apis.insertCredit({ 'firstname': firstname, 'lastname': lastname, 'url': url, 'isArtist': isArtist, 'role': role }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
                console.log(error.response)
            }
        }

    }
    handleCheckBox = (event) => {
        event.persist()
        this.setState({ [event.target.id]: event.target.checked });
    }
    render = () => {
        const inputs = { 'firstname': "Prénom", 'lastname': "Nom", 'url': "Url", 'role': "Role" }
        let iuCredit = this.props.type === 'insert' || this.props.type === 'update'
        return (
            <form onSubmit={(event) => { this.props.type === 'insert' ? this.insertCredits(event) : this.props.type === 'update' ? this.updateCredits(event) : this.deleteCredits(event) }} id={this.props.type + '-credit'} method="post">
                {
                    this.state.success !== "" &&
                    <div className="success">{this.state.success}</div>

                }
                {
                    this.state.error !== "" &&
                    <div className="error">{this.state.error.message}</div>

                }
                {
                    iuCredit &&
                    <div>
                        {
                            Object.keys(inputs).map((i, item) => (
                                <p key={i + '_' + this.props.type}>
                                    <span>{inputs[i]}</span>
                                    <input type="text" name={i} className="fiche_input" id={i + "_" + this.props.type + "_credit"} value={this.state[i]} placeholder={inputs[i]} onChange={this.handleChange} />
                                </p>
                            ))
                        }
                        <p>Est artiste ? <input type="checkbox" name="isArtist" id="isArtist" onChange={this.handleCheckBox} checked={this.state.isArtist} /></p>
                        <p>Est partenaire ? <input type="checkbox" name="isPartner" id="isPartner" onChange={this.handleCheckBox} checked={this.state.isPartner} /></p>
                    </div>
                }

                <button type="submit">{this.props.type === 'insert' ? 'Ajouter' : (this.props.type === 'update' ? 'Modifier' : 'Supprimer')}</button>
            </form>
        )
    }
}

export class CreditsSelector extends Component {

    render = () => {

        return (
            <select id={'select_' + this.props.type} onChange={this.props.queryCredit}>
                {this.props.credits.map(credit => {
                    return <option key={credit._id} value={credit._id} >{credit.firstname} {credit.lastname}</option>
                })}
            </select>
        )
    }
}