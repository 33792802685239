import axios from 'axios'
import errorlogger from '../utils/errorlogger'
const user = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/user',
})

export const signupUser = payload => {
    user.post(`/signup`, payload)
        .catch((error) => {
            console.log(errorlogger(error))
        });

}
export const loginUser = payload => user
    .post(
        `/login`,
        payload,
        {
            validateStatus: function (status) {
                return status >= 200 && status < 500;
            }
        })
    .catch((error) => {
        errorlogger(error)
    });

export const infoUser = payload => {

    return user
        .get(`/me`, { params: {}, headers: payload })
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            console.log(errorlogger(error))
            return errorlogger(error)
        });

}
export const getAllUsers = payload => {

    return user
        .get(`/all`, { params: {}, headers: payload })
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            console.log(errorlogger(error))
        });

}
export const resetPassword = payload => {
    return user.post(`/forgot-password`, payload)
        .then((e) => {
            return { success: e }
        })
        .catch((error) => {
            if (error.response) {
                return { error: error.response.data }
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error : ', error.message);
            }
            console.log(error.config);
        });

}
export const testResetToken = payload => user.get('/reset', { params: payload })

export const updatePasswordViaEmail = payload => user.put('/updatePasswordViaEmail', payload)

const users = {
    signupUser,
    infoUser,
    loginUser,
    getAllUsers,

    testResetToken,
    updatePasswordViaEmail,
}
export default users