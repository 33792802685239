import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next';
import i18n from '../utils/i18n';
import apis from '../api/api'

class FicheContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            artist: [],
            keywords: []
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.infos !== this.props.infos || prevProps.lang !== this.props.lang) {
            this.findArtist(this.props.infos.artist)
        }
    }
    componentDidMount = () => {
        if (this.props.infos) {
            this.findArtist(this.props.infos.artist)
        }
    }
    findArtist = async (artist_ids) => {
        await apis.getCreditByArrayId({ 'ids': artist_ids }).then(artist => {
            this.setState({ artist: artist.data.data })
        }).catch(e => console.log(e.response))
    }
    static propTypes = {
        infos: PropTypes.object.isRequired,
    }
    handleKeywordClick = (keyword) => {
        this.props.setSearchedValue(keyword)
        this.props.closeFiche()
    }
    sortByTitle = (array) => {
        const newarray = array.sort((a, b) => {
            let nameA = '', nameB = '';
            if (i18n.language === 'en') {
                nameA = a.title_en.toLowerCase()
                nameB = b.title_en.toLowerCase()
            }
            else {
                nameA = a.title_fr.toLowerCase()
                nameB = b.title_fr.toLowerCase()
            }
            return nameA.localeCompare(nameB);
        });
        return newarray;
    }
    getVideoId(str) {
        return str.split('https://vimeo.com/')[1];
    }

    render() {
        const { t, infos, all_keywords } = this.props
        const { artist } = this.state
        const keywords = this.sortByTitle(infos.keywords.map((item, i) => all_keywords[item]))
        const year = i18n.language === 'en' ? infos.year.replace('en cours', 'ongoing') : infos.year
        const title = i18n.language === 'en' ? infos.title_en : infos.title_fr
        if (typeof infos !== 'undefined') {
            return (
                <div className="fiche-contenu">
                   

                    <header>
                        <h1 className="title" data-text={`${title} | ${year}`}>{`${title} | ${year}`}</h1>
                        <div className='artists'>
                            {
                                artist ?
                                    artist.map((element, i) => {
                                        return (
                                            <span key={element._id} className="artist">
                                                {
                                                    element.url ?
                                                        <a href={element.url} target="_blank" rel="noopener noreferrer" >{element.firstname} {element.lastname}</a>
                                                        :
                                                        <span className="link">{element.firstname} {element.lastname}</span>
                                                }
                                                {element.role && <span>({element.role})</span>}
                                                {artist.length > 1 && i < artist.length - 1 && ' & '}
                                            </span>
                                        )
                                    })
                                    :
                                    'loading...'
                            }
                        </div>
                        {infos.url ?
                            infos.url.includes('vimeo') ?
                                <button className="link url" onClick={() => this.props.setModalContent('vimeo', infos)} >{t('fiche.access')} </button>
                                :
                                < a className="link url" href={infos.url} target="_blank" rel="noopener noreferrer" >{infos.url}</a>
                            :
                            infos._id === '5ea99dafd799511937c35b72' ?
                                <button className="link url" onClick={() => this.props.setModalContent('framed')} >{t('fiche.access')} </button>
                                :

                                <div className="link url">** À VENIR **</div>
                        }

                    </header>

                    <section className="fiche-keywords">
                        <h4>{t('fiche.keywords')} :</h4>
                        {
                            typeof all_keywords !== 'undefined' ?
                                <div className="keyword">{keywords.map((item, i) => {
                                    return <span key={item._id} className='mots-cle' onClick={() => this.handleKeywordClick(item)}>{i18n.language === 'en' ? item.title_en : item.title_fr}</span>
                                })}</div>
                                :
                                <h3>
                                    {t('site.loading')}
                                </h3>
                        }
                    </section>
                    <section className="fiche-texte">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: i18n.language === 'en' ? infos.body_en : infos.body_fr
                            }}></div>

                    </section>
                </div >
            );
        }
        else {
            return <h3>{t('fiche.none')}</h3>
        }
    }
}

export default withTranslation()(FicheContent)