import React, { Component } from 'react'
import apis from '../../api/api'
import i18n from '../../utils/i18n';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

export class FicheInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            title_fr: '',
            title_en: '',
            year: '',
            url: '',
            artist: [],
            keywords: [],
            all_keywords: null,
            all_artists: null,
            body_fr: null,
            body_en: null,
            success: "",
            error: "",
            editor_fr: null,
            editor_en: null
        }
    }
    componentDidUpdate = (prevProps, prevStates) => {

        if (prevProps.type !== this.props.type || prevProps.fiche !== this.props.fiche) {
            this.updateFields()
        }
    }
    componentDidMount = async () => {

        await apis.getAllKeywords().then(async items => {
            items = items.data.data
            this.setState({
                all_keywords: items,
            })
        })
        await apis.getAllArtists().then(async items => {
            items = items.data.data
            this.setState({
                all_artists: items,
            })
        })
    }
    handleChange = (event) => {
        if (event.target) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    onEditorChange = (editor) => {
        this.setState({
            body_fr: this.state.editor_fr.getData(),
            body_en: this.state.editor_en.getData(),
        });
    }
    updateFields = async () => {
        if (this.props.type === 'insert') {
            this.setState({
                id: '',
                title_fr: '',
                title_en: '',
                year: '',
                artist: '',
                url: '',
                keywords: [],
                body_fr: '',
                body_en: ''
            })
        }
        else {
            let { _id, title_fr, title_en, artist, url, keywords, body_fr, body_en, year } = this.props.fiche
            await apis.getAllKeywordsWithKeys().then(items => {
                items = items.data.data
                keywords = keywords.map((id) => {
                    return items[id]
                })
                this.setState({
                    'keywords': keywords
                })
            })
            this.findArtist(artist)

            this.setState({
                id: _id,
                title_fr: title_fr,
                title_en: title_en,
                year: year,
                url: url,
                keywords: keywords,
                body_fr: body_fr,
                body_en: body_en
            })
        }
    }
    deleteFiche = async (e) => {
        e.preventDefault()
        let id = this.state.id
        try {
            await apis.deleteFicheById(id).then(infos => {
                this.setState({
                    success: "Fiche supprimée"
                })
                this.updateFields()
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({

                    error: error.response.data
                })
            }
        }
    }
    updateFiche = async (e) => {
        e.preventDefault()
        const { id, title_fr, title_en, artist, url, year, body_en, body_fr, keywords } = this.state
        let artists = artist.map((artist) => artist._id)
        let keywords_i = keywords.map((keyword) => keyword._id)

        try {
            await apis.updateFicheById(id, { "title_fr": title_fr, "title_en": title_en, "artist": artists, "url": url, "keywords": keywords_i, "body_fr": body_fr, "body_en": body_en, "year": year }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.updateFields()
                this.props.callback()

            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }
    }
    findArtist = async (artist_ids) => {
        await apis.getCreditByArrayId({ 'ids': artist_ids }).then(artist => {
            this.setState({ artist: artist.data.data })
        }).catch(e => console.log(e.response))
    }
    insertFiche = async (e) => {
        e.preventDefault()
        const { title_fr, title_en, artist, url, year, body_en, body_fr, keywords } = this.state
        let artists = artist.map((artist) => artist._id)
        let keywords_i = keywords.map((keyword) => keyword._id)
        try {
            await apis.insertFiche({ "title_fr": title_fr, "title_en": title_en, "artist": artists, "url": url, "keywords": keywords_i, "body_fr": body_fr, "body_en": body_en, "year": year }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.updateFields()
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }

    }
    setSelectedKeywords = (keywords) => {
        this.setState({ keywords: keywords })
    }
    setSelectedArtists = (artists) => {
        this.setState({ artist: artists })
    }
    render = () => {
        const inputs = { 'title_fr': "Titre Francais",'title_en':"Titre Anglais", 'year': 'Année', 'url': "Url" }
        let afficherInput = this.props.type === 'insert' || this.props.type === 'update'
        let optionsKeywords = this.state.all_keywords
        let optionsArtists = this.state.all_artists
        let ficheKeywords = this.state.keywords
        let ficheArtists = this.state.artist
        let keywords_label = i18n.language === 'en' ? 'title_en' : 'title_fr';
        return (
            <form onSubmit={(event) => { this.props.type === 'insert' ? this.insertFiche(event) : this.props.type === 'update' ? this.updateFiche(event) : this.deleteFiche(event) }} id={this.props.type + '-fiche'} method="post">
                {
                    this.state.success !== "" &&
                    <div className="success">{this.state.success}</div>

                }
                {
                    this.state.error !== "" &&
                    <div className="error">{this.state.error.message} : <br /> {this.state.error.error.message}</div>

                }
                {
                    afficherInput &&
                    <div>
                        {
                            Object.keys(inputs).map((i, item) => (
                                <p key={i + '_' + this.props.type}>
                                    <span>{inputs[i]} : </span>
                                    <input type="text" name={i} className="fiche_input" id={i + "_" + this.props.type + "_fiche"} value={this.state[i]} placeholder={inputs[i]} onChange={this.handleChange} />

                                </p>
                            ))
                        }

                        <div>
                            <span> Artistes :</span>
                            {
                                optionsArtists && optionsArtists.length > 0 ?
                                    <Typeahead
                                        className="fiche_input"
                                        clearButton
                                        selected={ficheArtists}
                                        id={"artists_" + this.props.type + "_fiche"}
                                        labelKey={option => `${option.firstname} ${option.lastname}`}
                                        multiple
                                        options={optionsArtists}
                                        onChange={this.setSelectedArtists}
                                        placeholder="Choisir les artistes"
                                    />
                                    :
                                    'Loading...'
                            }
                        </div>
                        <div>
                            <span> Keywords :</span>
                            {
                                optionsKeywords && optionsKeywords.length > 0 ?
                                    <Typeahead
                                        className="fiche_input"
                                        clearButton
                                        selected={ficheKeywords}
                                        id={"keywords_" + this.props.type + "_fiche"}
                                        labelKey={keywords_label}
                                        multiple
                                        options={optionsKeywords}
                                        onChange={this.setSelectedKeywords}
                                        placeholder="Choisir les mots-clés"
                                    />
                                    :
                                    'Loading...'
                            }
                        </div>

                        <div>
                            <span> Corps en français :</span>
                            {this.state.body_fr !== null || this.props.type === 'insert' ?
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.body_fr}
                                    onChange={this.onEditorChange}
                                    onInit={(editor) => this.setState({ editor_fr: editor })}
                                />
                                :
                                'Loading...'
                            }
                        </div>
                        <div>
                            <span> Corps en anglais :</span>
                            {this.state.body_en !== null || this.props.type === 'insert' ?
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.body_en}
                                    onChange={this.onEditorChange}
                                    onInit={(editor) => this.setState({ editor_en: editor })}

                                />
                                :
                                'Loading...'
                            }
                        </div>
                    </div>

                }

                <button type="submit">{this.props.type === 'insert' ? 'Ajouter' : (this.props.type === 'update' ? 'Modifier' : 'Supprimer')}</button>
            </form>
        )
    }
}

export class FicheSelector extends Component {

    render = () => {
        return (
            <select id={'select_' + this.props.type} onChange={this.props.queryFiche}>
                {this.props.fiches.map(oeuvre => {
                    return <option key={oeuvre._id} value={oeuvre._id} >{oeuvre.title_en}</option>
                })}
            </select>
        )

    }
}