import React, { Component } from 'react'
import apis from '../../api/api'
import PropTypes from 'prop-types'
export class UsersInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            username: "",
            email: "",
            createdAt: "",
    
            success: "",
            error: "",
        }
    }
    componentDidUpdate = (prevProps, prevStates) => {
        if (prevProps.user !== this.props.user) {
            let { _id, title, artist, url, keywords, body } = this.props.user

            this.setState({
                id: _id,
                title: title,
                artist: artist,
                url: url,
                keywords: keywords,
                body: body
            })
        }
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    deleteUsers = async (e) => {
        e.preventDefault()
        let id = this.state.id
        try {
            await apis.deleteUsersById(id).then(infos => {
                this.setState({
                    success: "Users supprimée"
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({

                    error: error.response.data
                })
            }
        }
    }
    updateUsers = async (e) => {
        e.preventDefault()
        let id = this.state.id
        let title = this.state.title
        let artist = this.state.artist
        let url = this.state.url
        let keywords = this.state.keywords
        let body = this.state.body

        try {
            await apis.updateUsersById(id, { "title": title, "artist": artist, "url": url, "keywords": keywords, "body": body }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }
    }
    insertUsers = async (e) => {
        e.preventDefault()
        let title = this.state.title
        let artist = this.state.artist
        let url = this.state.url
        let keywords = this.state.keywords
        let body = this.state.body
        try {
            await apis.insertUsers({ "title": title, "artist": artist, "url": url, "keywords": keywords, "body": body }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }

    }
    render = () => {
        const inputs = { 'username': "Username", 'email': 'Courriel', 'createdAt': "Créer le " }
        let afuserrInput = this.props.type === 'insert' || this.props.type === 'update'
        return (
            <form onSubmit={(event) => { this.props.type === 'insert' ? this.insertUsers(event) : this.props.type === 'update' ? this.updateUsers(event) : this.deleteUsers(event) }} id={this.props.type+'-user'} method="post">
                {
                    this.state.success !== "" &&
                    <div className="success">{this.state.success}</div>

                }
                {
                    this.state.error !== "" &&
                    <div className="error">{this.state.error}</div>

                }
                {
                    afuserrInput &&
                    <div>
                        {
                            Object.keys(inputs).map((i, item) => (
                                <input key={i + '_' + this.props.type} type="text" name={i} className="user_input" id={i + "_" + this.props.type + "_user"} value={this.state[i]} placeholder={inputs[i]} onChange={(e) => this.handleChange(e)} />
                            ))
                        }
                    </div>

                }

                <button type="submit">{this.props.type === 'insert' ? 'Ajouter' : (this.props.type === 'update' ? 'Modifier' : 'Supprimer')}</button>
            </form>
        )
    }
}

export class UsersSelector extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired
    };
    render = () => {
        return (
            <select id={'select_' + this.props.type} onChange={this.props.queryUsers}>
                {this.props.users.map(oeuvre => {
                    return <option key={oeuvre._id} value={oeuvre._id} >{oeuvre.username}</option>
                })}
            </select>
        )
    }
}