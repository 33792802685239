import React, { Component } from 'react'
import { FicheInput, FicheSelector } from './FicheInput'
import { UsersInput, UsersSelector } from './UsersInput'
import { KeywordsInput, KeywordsSelector } from './KeywordsInput'
import apis from '../../api/api'
import users from '../../api/user'
import { CreditsInput, CreditsSelector } from './CreditsInput'

export default class Tab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            cur_item: [],
            cur_item_id: "",
            selected: "",
        }
    }
    componentDidMount = async () => {
        this.getAllItems()
    }
    openTab(evt, name) {
        this.setState({ 'selected': name, cur_item: [], cur_item_id: "" })
        if (name !== 'insert')
            this.getAllItems()
    }
    getAllItems = async () => {
        this.setState({ isLoading: true })
        if (this.props.type === 'fiches') {
            await apis.getAllFiches().then(items => {
                items = items.data.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0],
                    cur_item_id: this.state.items[0]._id

                })
            })
        }
        else if (this.props.type === 'users') {
            await users.getAllUsers().then(items => {
                items = items.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0],
                    cur_item_id: this.state.items[0]._id
                })
            })
        }
        else if (this.props.type === 'keywords') {
            await apis.getAllKeywords().then(items => {
                items = items.data.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0],
                    cur_item_id: this.state.items[0]._id

                })
            })
        }
        else if (this.props.type === 'credits') {
            await apis.getAllCredits().then(items => {
                items = items.data.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0],
                    cur_item_id: this.state.items[0]._id

                })
            })
        }
    }
    setSelected = (e) => {
        e.persist()
        let id = e.target.value
        this.setState({ cur_item_id: id }, this.queryItem)

    }
    queryItem = async () => {
        let id = this.state.cur_item_id;
        const { type } = this.props
        if (type === "fiches") {
            await apis.getFicheById(id).then(fiche => {
                this.setState({ cur_item: fiche.data.data })
            })
        }
        else if (type === "users") {
            await apis.getFicheById(id).then(fiche => {
                this.setState({ cur_item: fiche.data.data })
            })
        }
        else if (type === "keywords") {
            await apis.getKeywordById(id).then(key => {
                this.setState({ cur_item: key.data.data })
            })
        }
        else if (type === "credits") {
            await apis.getCreditById(id).then(credit => {
                this.setState({ cur_item: credit.data.data })
            })
        }
    }
    render = () => {
        return (
            <div className="tabs">

                <div className="tab">
                    <button className={"subtablinks " + (this.state.selected === 'insert' && 'active')} onClick={(event) => this.openTab(event, 'insert')}>Ajouter</button>
                    <button className={"subtablinks " + (this.state.selected === 'update' && 'active')} onClick={(event) => this.openTab(event, 'update')}>Modifier</button>
                    <button className={"subtablinks " + (this.state.selected === 'delete' && 'active')} onClick={(event) => this.openTab(event, 'delete')}>Supprimer</button>
                </div>

                {this.state.selected !== "" ?
                    <div id={this.state.selected + '-' + this.props.type} className="subtabcontent active">
                        {(this.state.selected === 'update' || this.state.selected === 'delete') ?
                            this.state.items.length > 0 ?
                                this.props.type === 'fiches' ?
                                    <FicheSelector type={this.state.selected} fiches={this.state.items} queryFiche={this.setSelected} />
                                    :
                                    this.props.type === 'users' ?
                                        <UsersSelector type={this.state.selected} users={this.state.items} queryUser={this.setSelected} />
                                        :
                                        this.props.type === 'credits' ?
                                            <CreditsSelector type={this.state.selected} credits={this.state.items} queryCredit={this.setSelected} />
                                            :
                                            this.props.type === 'keywords' &&
                                            <KeywordsSelector type={this.state.selected} keywords={this.state.items} queryKeyword={this.setSelected} />
                                :
                                <p>Aucun{this.props.type === 'fiches' && 'e'} {this.props.type} existant{this.props.type === 'fiches' && 'e'}</p>
                            :
                            <p>Création d'un{this.props.type === 'fiches' && 'e'} {this.props.type === 'fiches' ? 'nouvelle' : 'nouveau'} {this.props.type}</p>

                        }
                        {
                            this.props.type === 'fiches' ?
                                <FicheInput type={this.state.selected} fiche={this.state.cur_item} callback={this.queryItem} />
                                :
                                this.props.type === 'users' ?
                                    <UsersInput type={this.state.selected} user={this.state.cur_item} callback={this.queryItem} />
                                    :
                                    this.props.type === 'credits' ?
                                        <CreditsInput type={this.state.selected} credit={this.state.cur_item} callback={this.queryItem} />
                                        :
                                        this.props.type === 'keywords' &&
                                        <KeywordsInput type={this.state.selected} keyword={this.state.cur_item} callback={this.queryItem} />
                        }
                    </div>
                    :
                    this.props.type
                }
            </div>
        )
    }
}