import React, { Component } from 'react'

import { withTranslation } from 'react-i18next';

class BottomBar extends Component {

    capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    showHelp = () => {
        this.props.setModalContent('info')
    }

    render() {
        const { t, isDark, lang, page_is_expo } = this.props;
        const newLang = lang === 'en' ? 'fr' : 'en';
        return (
            <section id="bottom-bar">
                <nav>
                    <ul>
                        <li><button id={!isDark ? "dark-mode" : "light-mode"} onClick={this.props.setDisplayMode}>{!isDark ? this.props.t('buttonbar.dark') : t('buttonbar.light')}</button></li>
                        <li><button id="btn-info" onClick={this.showHelp}>{t('buttonbar.info')}</button></li>
                        {page_is_expo &&
                            <li><button id="btn-tout" onClick={this.props.setDisplayGrille}>{this.props.isGrille ? t('buttonbar.search') : t('buttonbar.see')}</button></li>

                        }
                        <li><button id="btn-media" onClick={this.props.changePage}>{this.props.page_is_expo ? t('buttonbar.library') :t('buttonbar.exhibition')}</button></li>
                        <li><button id={"btn-" + newLang} onClick={() => this.props.changeLanguage(newLang)}>{this.capitalize(newLang)}</button></li>
                    </ul>
                </nav>
            </section >
        );
    }
}

export default withTranslation()(BottomBar)