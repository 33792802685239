import React, { Component } from 'react'

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from 'jquery';

import apis from '../api/api'
import Typed from 'react-typed';

import { withTranslation } from 'react-i18next';

import SearchError from './SearchError'
class SearchBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searched_value: '',
            value: '',
            infos_keyword: null,
            error: false,
            isEmpty: true,
        }
    }
    handleSubmit(event) {

        event.preventDefault();
        let value = $('#formulaire_input').val().toLowerCase()
        this.keywordExist(value)
    }
    hideError = (e) => {
        $('.search-error').slideUp('slow');
    }

    keywordExist = (keyword) => {
        if (keyword !== "") {
            this.setState({ isEmpty: false })
            keyword = keyword.toLowerCase()
            this.setState({ searched_value: keyword }, async () => {
                await apis.getKeywordByTitle(keyword)
                    .then(async (result) => {
                        if (result.status === 200) {
                            this.setState({ error: false })
                            let keyword = result.data.data
                            this.setState({ infos_keyword: keyword })
                            this.props.setSearchedKeyword(keyword)
                        }
                        else {
                            this.props.setSearchedKeyword(null)
                            if (keyword === 'rechercher' || keyword === 'research' || keyword === 're|search') {
                                this.props.setModalContent('framed')
                            }
                            else if (keyword === 'admin') {
                                this.props.setModalContent('admin')
                            }
                            else if (keyword === 'cmd') {
                                this.props.setModalContent('terminal')
                            }
                            else {
                                this.setState({ error: true })
                            }
                        }
                    })
                    .catch((e) => {
                        this.props.setSearchedKeyword(null)
                        if (keyword === 'rechercher' || keyword === 'chercher' || keyword === 'research' || keyword === 'search' || keyword === 're|search') {
                            this.props.setModalContent('framed')
                        }
                        else if (keyword === 'admin') {
                            this.props.setModalContent('admin')
                        }
                        else if (keyword === 'vérifier') {
                            this.props.setModalContent('terminal')
                        }
                        else {
                            this.setState({ error: true })
                        }
                    })
            })
        }
        else {
            this.setState({ searched_value: '', isEmpty: true })
            if (this.state.searched_value !== '') {
                this.props.setSearchedKeyword(null)
                this.setState({ error: false })
            }
            else {
                this.props.setSearchedKeyword(null)
                this.setState({ error: true })
            }
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.searched_keyword !== this.props.searched_keyword) {
            if (this.props.searched_keyword !== null) {

                let title_en = this.props.searched_keyword.title_en;
                let title_fr = this.props.searched_keyword.title_fr;
                if (this.state.value !== title_en && this.state.value !== title_fr) {
                    if (this.props.lang === 'en') {
                        this.setState({ value: title_en })
                    }
                    else {
                        this.setState({ value: title_fr })
                    }
                }
                this.hideError()
            }
        }
        if (prevProps.isGrille !== this.props.isGrille) {
            this.setState({ value: '', error: false })
            this.hideError()
        }
    }
    handleChange = (event) => {
        if (event.target) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    render() {
        const { t, lang } = this.props;
        const { searched_value, value, isEmpty } = this.state
        return (
            <section id="form-nav" className={this.props.isTop ? "search-top" : 'search-bar'}>
                <form onSubmit={(event) => this.handleSubmit(event)} id="formulaire" method="post">

                    <Typed

                        strings={t('searchbar.placeholder', { returnObjects: true })}
                        typeSpeed={100}
                        backSpeed={50}
                        attr='placeholder'
                        bindInputFocusEvents={true}
                        loop={true}
                    >
                        <input type="text" name="value" id="formulaire_input" className="typed" value={value} onChange={this.handleChange} />
                    </Typed>
                    <button className='search-btn' ><FontAwesomeIcon icon={faSearch} /></button>
                </form>

                <SearchError empty={isEmpty} show={this.state.error} searched_value={searched_value} lang={lang} />

            </section>
        );
    }
}

export default withTranslation()(SearchBar);