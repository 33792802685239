
import React, { Component } from 'react'

export class MediaSelector extends Component {

    render = () => {
        return (
            <select id="select_medias" onChange={this.props.queryMedia}>
                <option key="-1" value="-1" >Nouveau Media</option>
                {this.props.medias && this.props.medias.map(media => {
                    return <option key={media._id} value={media._id} >{media.title_fr}</option>
                })}
            </select>
        )

    }
}