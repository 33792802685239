import React, { Component } from 'react'
import apis from '../../api/api'

export class KeywordsInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            title_fr: "",
            title_en: "",
            success: "",
            error: "",
        }
    }
    componentDidMount = () => {
        let { _id, title_fr, title_en } = this.props.keyword
        this.setState({
            id: _id,
            title_fr: title_fr,
            title_en: title_en,
        })
    }
    componentDidUpdate = (prevProps, prevStates) => {
        if (prevProps.keyword !== this.props.keyword) {
            let { _id, title_fr, title_en } = this.props.keyword
            this.setState({
                id: _id,
                title_fr: title_fr,
                title_en: title_en,
            })
        }
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    deleteKeywords = async (e) => {
        e.preventDefault()
        let id = this.state.id
        try {
            await apis.deleteKeywordById(id).then(infos => {
                this.setState({
                    success: "Keywords supprimée"
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({

                    error: error.response.data
                })
            }
        }
    }
    updateKeywords = async (e) => {
        e.preventDefault()
        let id = this.state.id
        let title_fr = this.state.title_fr
        let title_en = this.state.title_en
        try {
            await apis.updateKeywordById(id, { 'title_fr': title_fr, 'title_en': title_en }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            console.log(error)
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
            }
        }
    }
    insertKeywords = async (e) => {
        e.preventDefault()

        let title_fr = this.state.title_fr
        let title_en = this.state.title_en

        try {
            await apis.insertKeyword({ 'title_fr': title_fr, 'title_en': title_en }).then(infos => {
                this.setState({
                    success: infos.data.message
                })
                this.props.callback()
            })
        }
        catch (error) {
            if (error.response) {
                this.setState({
                    error: error.response.data
                })
                console.log(error.response)
            }
        }

    }
    render = () => {
        const inputs = { 'title_fr': "Titre Francais", 'title_en': "Titre Anglais" }
        let iuKeyword = this.props.type === 'insert' || this.props.type === 'update'
        return (
            <form onSubmit={(event) => { this.props.type === 'insert' ? this.insertKeywords(event) : this.props.type === 'update' ? this.updateKeywords(event) : this.deleteKeywords(event) }} id={this.props.type + '-keyword'} method="post">
                {
                    this.state.success !== "" &&
                    <div className="success">{this.state.success}</div>

                }
                {
                    this.state.error !== "" &&
                    <div className="error">{this.state.error.message}</div>

                }
                {
                    iuKeyword &&
                    <div>
                        {
                            Object.keys(inputs).map((i, item) => (
                                <p key={i + '_' + this.props.type}>
                                    <span>{inputs[i]}</span>
                                    <input type="text" name={i} className="keyword_input" id={i + "_" + this.props.type + "_keyword"} value={this.state[i]} placeholder={inputs[i]} onChange={(e) => this.handleChange(e)} />
                                </p>
                            ))
                        }
                    </div>

                }

                <button type="submit">{this.props.type === 'insert' ? 'Ajouter' : (this.props.type === 'update' ? 'Modifier' : 'Supprimer')}</button>
            </form>
        )
    }
}

export class KeywordsSelector extends Component {

    render = () => {
        return (
            <select id={'select_' + this.props.type} onChange={this.props.queryKeyword}>
                {this.props.keywords.map(oeuvre => {
                    return <option key={oeuvre._id} value={oeuvre._id} >{oeuvre.title_fr} / {oeuvre.title_en}</option>
                })}
            </select>
        )
    }
}