import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Oeuvre extends Component {
    default_column_oeuvre = {
        isSearched: false,
        width: '100%',
        filter: "blur(5px)",
        zIndex: "1",
        margin: "0 auto -3em auto",
        cursor: "default",
        transform: "scale(1)"
    }

    searched_column_oeuvre = {
        isSearched: false,
        width: '100%',
        filter: "blur(5px)",
        zIndex: "1",
        margin: "0",
        cursor: "default",
        transform: "scale(1)"
    }

    // searched_column_oeuvre = {
    //     isSearched: false,
    //     width: '100%',
    //     filter: "blur(5px)",
    //     zIndex: "1",
    //     margin: "0",
    //     cursor: "default",
    //     transform: "scale(1)"

    // }
    searched_column_oeuvre_selected = {
        isSearched: true,
        width: '100%',
        filter: "none",
        zIndex: "1",
        margin: "0",
        cursor: "pointer",
        transform: "scale(1)"

    }

    default_grille_oeuvre =
        {
            isSearched: true,
            width: 'inherit',
            filter: "none",
            zIndex: "1",
            margin: "0",
            cursor: "pointer",
            transform: "scale(1)"

        }
    searched_grille_oeuvre =
        {
            isSearched: false,
            width: '33%',
            filter: "blur(5px)",
            zIndex: "1",
            margin: "0",
            cursor: "default",
            transform: "scale(1)"
        }
    searched_grille_oeuvre_selected =
        {
            isSearched: true,
            width: '33%',
            filter: "none",
            zIndex: "1",
            margin: "0",
            cursor: "pointer",
            transform: "scale(1)"

        }

    constructor(props) {
        super(props);
        this.state = {}
        window.addEventListener('load', this.handleLoad);
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.updateOeuvre()
        }

    }
    componentDidMount = () => {
        this.updateOeuvre()
    }

    updateOeuvre = async () => {
        if (this.props.isGrille) {

            if (this.props.isSearching) {
                if (this.props.isSearched) {
                    this.setState(this.searched_grille_oeuvre_selected)
                } else {
                    this.setState(this.searched_grille_oeuvre)
                }
            }
            else {
                this.setState(this.default_grille_oeuvre)
            }
        }
        else {
            if (this.props.isSearching) {
                if (this.props.isSearched) {
                    this.setState(this.searched_column_oeuvre_selected)
                }
                else {
                    this.setState(this.searched_column_oeuvre)
                }
            }
            else {
                let inverted = this.props.limits.size - this.props.index
                let blur = inverted - 1 + 3
                if (blur > 15) blur = 15

                let diff_size = 50 / this.props.limits.size
                let transform = 0.5 + ((diff_size / 100) * this.props.index)

                // let width = this.props.limits.minwidth + this.props.index * 3

                let height = 48;
                let margin = height - 10;
                let defaults = this.default_column_oeuvre
                // defaults.width = width + '%'
                defaults.filter = "blur(" + blur + "px)"
                defaults.zIndex = "1" + this.props.index
                defaults.margin = "0 0 -" + margin + "px 0"
                defaults.transform = "scale(" + transform + ")"
                this.setState(defaults)





            }

        }

    }
    static propTypes = {
        infos: PropTypes.object.isRequired,
    }

    showFiche(e) {
        if (!this.props.isSearching && this.props.isGrille) {
            this.props.setInfosFiche(this.props.infos, this.props.all_keywords)
        }
        if (this.props.isSearched) {
            this.props.setInfosFiche(this.props.infos, this.props.all_keywords)
        }
    }
    render() {
        let styleSearching = { width: '100%', filter: this.state.filter, cursor: this.state.cursor }
        let styleGrid = { filter: this.state.filter, cursor: this.state.cursor }
        // let styleSearching = { width: this.state.width, filter: this.state.filter, zIndex: this.state.zIndex, margin: this.state.margin, transform: this.state.transform, cursor: this.state.cursor }
        let styleBlur = { width: this.state.width, filter: this.state.filter, zIndex: this.state.zIndex, margin: this.state.margin, transform: this.state.transform, cursor: this.state.cursor }
        const { lang, infos: { title_en, title_fr, _id }, isGrille, isSearching } = this.props
        return (

            <article id={_id} onClick={(e) => this.showFiche(e)} className="item-oeuvre" style={isGrille ? styleGrid : (isSearching ? styleSearching : styleBlur)}>
                <h1>
                    {
                        lang === 'en' ?
                            title_en
                            :
                            title_fr
                    }
                </h1>
            </article>
        );
    }

}

export default Oeuvre