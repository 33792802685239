import React, { Component } from 'react'
import apis from '../../../api/api'
import { MediaSelector } from './MediaSelector'
import MediaForm from './MediaForm'

class Mediatheque extends Component {
    constructor(props) {
        super(props)
        this.state = {
            medias: null,
            selected_media: null,
            selected_media_id: -1,
        }
    }
    componentDidMount = () => {
        apis.getAllMedias().then(res => {
            console.log(res.data.data)
            this.setState({ medias: res.data.data })
        })
    }
    queryMedia = (e) => {
        e.persist()
        let id = e.target.value
        console.log(id)
        this.setState({ selected_media_id: id }, async () =>
            await apis.getMedia(id).then(media => {
                console.log(media.data.data)
                this.setState({ selected_media: media.data.data })
            })
        )
    }
    render() {
        const { medias, selected_media, selected_media_id } = this.state
        return (
            <div>
                <MediaSelector medias={medias} queryMedia={this.queryMedia} />
                <MediaForm selected_media={selected_media} selected_media_id={selected_media_id} />
            </div>
        );

    }
}

export default Mediatheque