import React, { Component } from 'react'
import apis from '../api/api'

import FicheMedia from './FicheMedia'
import { withTranslation } from 'react-i18next';

class ListeMediatheque extends Component {
    constructor(props) {
        super(props)
        this.state = {
            medias: null
        }
    }
    componentDidMount = () => {
        apis.getAllMedias().then(medias => {
            this.setState({ medias: medias.data.data })
        })
    }
    render() {
        const { medias } = this.state
        const { t } = this.props
        return (
            <div className='mediatheque'>
                <h1>{t('buttonbar.library')}</h1>
                {
                    medias ?
                        <div className='liste-mediatheque'>
                            {medias.map((media, index) =>
                                <FicheMedia key={index} media={media} />
                            )}
                        </div>
                        :
                        "Loading...."

                }

            </div>
        );
    }
}
export default withTranslation()(ListeMediatheque)