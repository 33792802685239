import React, { Component } from 'react'
import axios from 'axios'
// import { Document, Page } from 'react-pdf';
import { withTranslation } from 'react-i18next';
import i18n from '../utils/i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faArrowLeft, faArrowRight, faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'
import { PDFReader } from 'react-read-pdf';
import ReactAudioPlayer from 'react-audio-player';

import FileViewer from 'react-file-viewer';

import errorLogger from '../utils/errorlogger'

class FicheMediaPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNumber: 1,
            numPages: 1,
            file: null,
            fileHeaders: null,
            fullscreen: false,
            open: false
        }

    }
    componentDidMount = () => {
        this.getFile()
        setTimeout(this.setOpen, 100)
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.media !== this.props.media) {
            this.getFile()
        }
    }
    setOpen = () => {
        this.setState({ open: !this.state.open })
    }
    setClose = () => {
        this.setState({ open: false })
        setTimeout(this.props.close, 500)

    }
    getLanguage = () => {
        return i18n.language ||
            (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
            'fr';
    };
    setNumPages = (numPages) => {
        this.setState({ numPages })
    }
    setPageNumber = (pageNumber) => {
        const { numPages } = this.state
        if (pageNumber < 1) {
            if (numPages % 2 === 0) {
                pageNumber = numPages - 1;
            }
            else {
                pageNumber = numPages;
            }
        }
        if (pageNumber > numPages) pageNumber = 1;
        this.setState({ pageNumber })
    }
    onDocumentLoadSuccess = (numPages) => {
        this.setNumPages(numPages);
    }
    isImage = (path) => {
        return (
            path.includes('.jpg') ||
            path.includes('.png') ||
            path.includes('.gif')
        )
    }
    isVideo = (path) => {
        return (
            path.includes('.mp4') ||
            path.includes('.mov') ||
            path.includes('.avi')
        )
    }
    isAudio = (path) => {
        return (
            path.includes('.mp3') ||
            path.includes('.wav') ||
            path.includes('.wma')
        )
    }
    isPdf = (path) => {
        return (
            path.includes('.pdf')
        )
    }
    isTxt = (path) => {
        return (
            path.includes('.docx')
        )
    }
    readTextFile = async file => {

        const options = {
            url: file,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        axios(options)
            .then(response => {
                console.log(response.status);
            });

        // console.log(text)
        // return <div>{text}</div>
    }
    getFile = () => {
        const path = this.props.media.file.path

        axios
            .get(path)
            .then((res) => {
                console.log(res)
                this.setState({ file: res.data, fileHeaders: res.headers })
            })
            .catch((e) => errorLogger(e))
    }
    readTextFile = file => {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status == 0) {
                    var allText = rawFile.responseText;
                    console.log("allText: ", allText);
                    this.setState({
                        fundData: allText
                    });
                }
            }
        };
        rawFile.send(null);
    };
    onDocumentComplete = (totalPage, title, otherObj) => {
        this.setNumPages(totalPage)
    }
    setFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen })
    }
    render() {
        const { media, t, lang } = this.props
        const path = media.file.path
        const { pageNumber, numPages, fullscreen, open } = this.state
        console.log(lang)
        // this.readTextFile(path)
        return (
            <div className={open ? "popup open" : "popup close"}>
                <button className="close-btn" onClick={this.setClose}>Close</button>
                {
                    fullscreen ?
                        <div className="fullscreen-popup">

                            {path &&
                                this.isAudio(path) ?
                                <div className="centered">
                                    <ReactAudioPlayer
                                        src={path}
                                        autoPlay
                                        controls
                                    />
                                </div>
                                :
                                this.isImage(path) ?
                                    <img src={path} width="100vw" height="100vh" />
                                    :
                                    this.isPdf(path) ?
                                        <div className='pdf-reader'>
                                            <div className='pdf-reader-body'>
                                                <magnify width={300}>

                                                    <div className='pdf-page left'>
                                                        <PDFReader url={path} page={pageNumber} onDocumentComplete={this.onDocumentComplete} maxScale={1} />
                                                    </div>
                                                    {
                                                        pageNumber + 1 <= this.state.numPages &&
                                                        <div className='pdf-page right'>
                                                            <PDFReader url={path} page={pageNumber + 1} onDocumentComplete={this.onDocumentComplete} maxScale={1} />
                                                        </div>
                                                    }
                                                </magnify>
                                            </div>
                                            {numPages > 2 &&
                                                <div>
                                                    <button className='prev-btn' onClick={() => this.setPageNumber(pageNumber - 2)}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
                                                    <button className='next-btn' onClick={() => this.setPageNumber(pageNumber + 2)}> <FontAwesomeIcon icon={faArrowRight} /> </button>
                                                </div>
                                            }
                                        </div>

                                        :
                                        this.isVideo(path) ?
                                            <video controls autostart="true" autoPlay src={path} type="video/mp4" width="100vw" height="100vh" />
                                            :
                                            this.isTxt(path) &&
                                            <FileViewer
                                                fileType={'docx'}
                                                filePath={path}
                                            />
                            }
                            <button className="fullscreen-btn" onClick={this.setFullscreen}><FontAwesomeIcon icon={faCompress} /></button>
                        </div>
                        :
                        <div>
                            <h1>{media.title_fr}</h1>
                            {path &&
                                this.isAudio(path) ?
                                <div className="centered">

                                    <ReactAudioPlayer
                                        src={path}
                                        autoPlay
                                        controls
                                    />
                                </div>
                                :
                                this.isImage(path) ?
                                    <img src={path} />
                                    :
                                    this.isPdf(path) ?
                                        <div className='pdf-reader'>
                                            <div className='pdf-reader-body'>
                                                <magnify width={300}>

                                                    <div className='pdf-page left'>
                                                        <PDFReader url={path} page={pageNumber} onDocumentComplete={this.onDocumentComplete} maxScale={1} />
                                                    </div>
                                                    {
                                                        pageNumber + 1 <= this.state.numPages &&
                                                        <div className='pdf-page right'>
                                                            <PDFReader url={path} page={pageNumber + 1} onDocumentComplete={this.onDocumentComplete} maxScale={1} />
                                                        </div>
                                                    }
                                                </magnify>
                                            </div>
                                            {numPages > 2 &&
                                                <div>
                                                    <button className='prev-btn' onClick={() => this.setPageNumber(pageNumber - 2)}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
                                                    <button className='next-btn' onClick={() => this.setPageNumber(pageNumber + 2)}> <FontAwesomeIcon icon={faArrowRight} /> </button>
                                                </div>
                                            }

                                            <p>page {pageNumber} {t('media.of')} {numPages}</p>
                                            <form method="get" action={path}>
                                                <button type="submit" className='download-btn'><FontAwesomeIcon icon={faDownload} />{t('media.download')}</button>
                                            </form>
                                        </div>

                                        :
                                        this.isVideo(path) ?
                                            <video controls autostart="true" autoPlay src={path} type="video/mp4" />
                                            :
                                            this.isTxt(path) &&
                                            <FileViewer
                                                fileType={'docx'}
                                                filePath={path}
                                            />

                            }
                            <div className="media-description" dangerouslySetInnerHTML={this.getLanguage() === 'en' ? { __html: media.description_en } : { __html: media.description_fr }} />
                            {/* <a href={file} class="btn" style={{margin:'auto',color:"white",clear:'both',float:'none'}}><FontAwesomeIcon icon={faDownload} /> Download</a> */}
                            <button className="fullscreen-btn" onClick={this.setFullscreen}><FontAwesomeIcon icon={faExpand} /></button>

                        </div>
                }

            </div >
        );
    }
}

export default withTranslation()(FicheMediaPopup)