import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import users from '../../api/user'
import { BeatLoader } from 'react-spinners';

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            username: '',
            keepConnect: 'off',
            token: '',
            isSignup: false,
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        let email = this.state.email
        let username = this.state.username
        let passwd = this.state.password
        if (email !== "") {
            if (passwd !== "") {
                this.setState({ isLoading: true, error: null })
                if (this.state.isSignup) {
                    await users.signupUser({ email: email, password: passwd, username: username });
                    this.setState({ isSignup: false })
                }
                else {
                    await
                        users.loginUser({ email: email, password: passwd }).then(info => {
                            this.setState({ isLoading: false })
                            if (typeof info.message !== 'undefined') {
                                this.setState({ error: info.message })
                            }
                            else {

                                this.setState({
                                    token: info.data.token,
                                    isLoading: false,
                                })
                                if (this.state.token !== "") {
                                    let isTemp = true
                                    if (this.state.keepConnect === 'on')
                                        isTemp = false

                                    this.props.setToken(this.state.token, isTemp)
                                }
                                else {
                                    this.setState({ error: "il y a une erreur" })
                                }
                            }
                        }).catch((error) => {
                            this.setState({ error: "il y a une erreur" })
                            console.error(error);
                        });
                }

            }
            else {
                this.setState({ error: "Veuillez entrez un mot de passe." })
            }
        }
        else {
            this.setState({ error: "Veuillez entrez un courriel." })
        }

    }
    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }
    render() {

        return (
            <div className='login'>
                <h1>Re|Search Exhibition</h1>
                {this.state.error &&
                    <div className="alert alert-danger" role="alert">
                        {this.state.error}
                    </div>
                }
                {this.props.error &&
                    <div className="alert alert-danger" role="alert">
                        {this.props.error}
                    </div>
                }
                {this.state.isLoading ?
                    <BeatLoader
                        color={'#e17b42'}
                        loading={this.state.isLoading}
                    />
                    :
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="email">
                            <Form.Label>Courriel</Form.Label>
                            <Form.Control type="email" placeholder="Entrer Courriel" onChange={this.handleChange} defaultValue={this.state.email} />

                        </Form.Group>

                        {this.state.isSignup &&
                            <Form.Group controlId="username">
                                <Form.Label>Nom d'utilisateur</Form.Label>
                                <Form.Control type="text" placeholder="Entrer le nom d'utilisateur" onChange={this.handleChange} defaultValue={this.state.username} />
                            </Form.Group>
                        }
                        <Form.Group controlId="password">
                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Entrer Mot de passe" onChange={this.handleChange} defaultValue={this.state.password} />
                        </Form.Group>
                        <Form.Group controlId="keepConnect">
                            <Form.Check type="checkbox" label="Me garder connecter" onChange={this.handleChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {this.state.isSignup ? "S'inscrire" : "Se Connecter"}
                        </Button>
                    </Form>
                }


            </div>
        )
    }
}
